/* eslint-disable no-use-before-define */
/* eslint-disable import/extensions */
import ConfigWoo from './ConfigWoo.js'

const WooCommerceAPI = require('woocommerce-api')

const WooCommerce = new WooCommerceAPI({
  url: ConfigWoo.url,
  consumerKey: ConfigWoo.consumerKey,
  consumerSecret: ConfigWoo.consumerSecret,
  wpAPI: true,
  version: 'wc/v3',
  verifySsl: false,
})

function products() {
  orders()
  return WooCommerce.getAsync('products').then(result => {
    console.log('products', JSON.parse(result.toJSON().body))
    return JSON.parse(result.toJSON().body)
  })
}
function orders() {
  return WooCommerce.getAsync('orders').then(result => {
    console.log('order', JSON.parse(result.toJSON().body))
    return JSON.parse(result.toJSON().body)
  })
}

function updateOrderStatus(orderId, status) {
  const data = {
    status,
  }
  return WooCommerce.putAsync(`order/${orderId}`, data)
    .then(response => JSON.parse(response.toJSON()))
    .catch(error => {
      console.log(error)
    })
}

export default {
  products,
  orders,
  updateOrderStatus,
}
