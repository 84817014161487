// export default {
//   consumerKey: 'ck_a54d6b8034214f86d5f143bb2e273c602a5df24f',
//   consumerSecret: 'cs_8d7a23cffc82222cff77d945e352c2ae4efe9a72',
// }

export default {
  url: 'http://liora42.sg-host.com',
  consumerKey: 'ck_a54d6b8034214f86d5f143bb2e273c602a5df24f',
  consumerSecret: 'cs_8d7a23cffc82222cff77d945e352c2ae4efe9a72',
  wpAPI: true,
  version: 'wc/v3',
}
