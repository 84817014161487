<template>
  <div>
    <!-- <table
      id="example"
      class="table table-hover responsive nowrap"
      style="width:100%"
    >
import { icon } from 'leaflet'
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Phone Number</th>
          <th>Profession</th>
          <th>Date of Birth</th>
          <th>App Access</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <a href="#">
              <div class="d-flex align-items-center">
                <div class="avatar avatar-blue mr-3">EB</div>

                <div class="">
                  <p class="font-weight-bold mb-0">Ethan Black</p>
                  <p class="text-muted mb-0">ethan-black@example.com</p>
                </div>
              </div>
            </a>
          </td>
          <td>(784) 667 8768</td>
          <td>Designer</td>
          <td>09/04/1996</td>
          <td>
            <div class="badge badge-success badge-success-alt">
              Enabled
            </div>
          </td>
          <td>
            <div class="dropdown">
              <button
                id="dropdownMenuButton2"
                class="btn btn-sm btn-icon"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  class="bx bx-dots-horizontal-rounded"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Actions"
                />
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <a
                  class="dropdown-item"
                  href="#"
                ><i class="bx bxs-pencil mr-2" /> Edit Profile</a>
                <a
                  class="dropdown-item text-danger"
                  href="#"
                ><i class="bx bxs-trash mr-2" /> Remove</a>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <a href="#">
              <div class="d-flex align-items-center">
                <div class="avatar avatar-pink mr-3">JR</div>

                <div class="">
                  <p class="font-weight-bold mb-0">Julie Richards</p>
                  <p class="text-muted mb-0">julie_89@example.com</p>
                </div>
              </div>
            </a>
          </td>
          <td> (937) 874 6878</td>
          <td>Investment Banker</td>
          <td>13/01/1989</td>
          <td>
            <div class="badge badge-success badge-success-alt">
              Enabled
            </div>
          </td>
          <td>
            <div class="dropdown">
              <button
                id="dropdownMenuButton2"
                class="btn btn-sm btn-icon"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  class="bx bx-dots-horizontal-rounded"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Actions"
                />
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton2"
              >
                <a
                  class="dropdown-item"
                  href="#"
                ><i class="bx bxs-pencil mr-2" /> Edit Profile</a>
                <a
                  class="dropdown-item text-danger"
                  href="#"
                ><i class="bx bxs-trash mr-2" /> Remove</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table> -->
    <div class="mb-2 row">
      <div class="col-md-8">
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Search Orer"
        />
      </div>
      <button class="btn btn-icon btn-primary">
        Search
      </button>
    </div>
    <b-table
      :items="orders"
      :fields="fields"
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <button
          class="primary btn-primary btn-icon"
          @click="$bvModal.show('modal-' + row.item.id)"
        >
          Update Order
        </button>
        <b-modal
          :id="'modal-' + row.item.id"
          title="Status"
          hide-footer
        >
          <b-form-select
            v-model="selectedStatus"
            :options="statusOptions"
            class="mb-2"
          />
          <button
            class="primary btn-primary btn-icon"
            @click="updateOrder(row.item.id)"
          >
            Update Status
          </button>
        </b-modal>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="20"
      :per-page="perPage"
      aria-controls="my-table"
      @change="paginateData"
    />
    <!-- <b-card title="Kick start your project 🚀">
import tableResponsiveVue, TableResponsive from './components/tableResponsive.vue';
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card>

    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BTable, BPagination, BFormInput, BModal, BFormSelect,
} from 'bootstrap-vue'

import axios from 'axios'

import WooService from '@/services/WooService'

export default {
  components: {
    // BCard,
    // BCardText,
    // BLink,
    BTable,
    BPagination,
    BFormInput,
    BModal,
    BFormSelect,
  },
  data() {
    return {
      fields: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'email', label: 'Email' },
        { key: 'amount', sortable: true },
        { key: 'order_key', label: 'Order Key' },
        { key: 'status', sortable: true },
        { key: 'actions', label: 'Action' },
      ],
      orders: [],
      employees: [
        {
          id: 101,
          name: 'Sam',
        },
        {
          id: 102,
          name: 'Jane',
        },
      ],
      selectedStatus: '',
      statusOptions: [
        { value: 'pending', text: 'Pending' },
        { value: 'processing', text: 'Processing' },
        { value: 'on-hold', text: 'on Hold' },
        { value: 'completed', text: 'Completed' },
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'refunded', text: 'Refunded' },
        { value: 'failed', text: 'Failed' },
        { value: 'trash', text: 'Traash' },
      ],
      perPage: 10,
      currentPage: 1,
      rows: 1,
      filter: null,
    }
  },
  mounted() {
    this.loadOrders()
  },
  methods: {
    async loadOrders() {
      // eslint-disable-next-line no-return-assign
      const orderList = []
      const rawdata = await WooService.orders()
      if (rawdata && rawdata.length) {
        rawdata.forEach(element => {
          const tempJson = {
            id: element.id,
            name: element.billing?.first_name + element.billing?.last_name,
            email: element.billing?.email,
            amount: element.total,
            order_key: element.order_key,
            status: element.status,
          }
          orderList.push(tempJson)
        })
      }
      this.orders = orderList
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length
      this.currentPage = 1
    },
    paginateData(pageData) {
      console.log(pageData)
    },
    async updateOrder(orderId) {
      // WooService.updateOrderStatus(orderId, this.selectedStatus)
      //   .then(response => {
      //     console.log(response.data)
      //   })
      //   .catch(error => {
      //     console.log(error.response.data)
      //   })
      const response = await axios.post('http://localhost:3000/order-update', { data: { orderId, status: this.selectedStatus } })
      console.log(response)
      // this.axiosIns.
      // orderId, status: this.selectedStatus }
      // const formdata = new FormData()
      // formdata.append('orderIs', orderId)
      // formdata.append('status', this.selectedStatus)
      // console.log(this.$http.post())
    },
  },
}
</script>

<style>

</style>
